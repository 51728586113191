import {WarningOutlined} from '@ant-design/icons';
import {Card} from 'antd';
import React, {FC} from 'react';

export const LyonAdditionalInfo: FC = (props) => {
  if ((process.env.REACT_APP_SITE_NAME ?? 'nimes') !== 'lyon') {
    return <div />;
  }

  return (
    <Card style={{marginTop: 12, textAlign: 'left'}}>
      <WarningOutlined style={{marginRight: 8}} />
      Pour les produits suivants, merci de bien vouloir passer commande
      directement sur{' '}
      <a href="https://store.nimes.interson-protac.com/">le site de Nîmes</a> :
      <ul>
        <li>MUSIQUE : Pianissimo, Earback, Adaptateur audio</li>
        <li>NOMADE : Game Ear</li>
        <li>BRUIT : Passtop OS, OR et EP2</li>
        <li>CHASSE : Stopgun E Classic, Passtop tir & chasse</li>
        <li>EAU : Aquason</li>
        <li>
          COMMUNICATION : Oreillette personnalisée, Passtop O.S conque pour tube
          acoustique, Obturateur communication, Secrethear
        </li>
      </ul>
    </Card>
  );
};

import {css} from '@emotion/core';
import {Button, Card, Col, Row} from 'antd';
import {FC, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  useCurrentCategory,
  useCurrentProduct,
  useCurrentSelectedOptions,
  useCurrentSubCategory,
  useCustomMarkFields,
} from '../../contexts/Quote';
import {LyonAdditionalInfo} from './LyonAdditionalInfo';

const domainCss = css`
  box-shadow: 3px 3px 10px 0px #ccc;
  padding: 2px;
  margin-bottom: 30px;
  .ant-btn {
    position: relative;
    top: 20px;
  }
`;

const categoryCss = css`
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin-bottom: 30px;
    border: none;

    .background {
      max-height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
      }

      .background-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:not(.transparent) {
          background-color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .foreground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      text-align: center;
      font-size: 30px;
      font-weight: bolder;
      white-space: pre-wrap;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      p {
        text-transform: uppercase;
        margin: auto;
        color: white;
        font-weight: bold;
      }
    }
  }
`;

const ProtectionProducts: FC<{parentPath: string}> = ({parentPath}) => {
  const [category] = useCurrentCategory();
  const [, setProduct] = useCurrentProduct();
  const [subCategory, setSubCategory] = useCurrentSubCategory();
  const [, setSelectedOptions] = useCurrentSelectedOptions();
  const [, setCustomMarkFields] = useCustomMarkFields();
  const history = useHistory();

  if (!category) {
    return null;
  }

  const onSelect = (_subCategory: string): void => {
    if (_subCategory !== subCategory) {
      setSubCategory(_subCategory);
      setProduct(undefined);
      setSelectedOptions([]);
      setCustomMarkFields(undefined);
    }

    history.push([parentPath, 'products'].join('/'));
  };

  return (
    <Row type="flex" gutter={10}>
      {Object.keys(category.products).map((key, index) => (
        <Col span={12} xl={8} key={index}>
          <div css={domainCss}>
            <Card css={categoryCss} bordered={false}>
              <div className="background">
                <img
                  alt={key}
                  src={`/protection/category/${key
                    .toLocaleLowerCase()
                    .replace(/\W+/g, '_')}.png`}
                />

                <div className="background-hover" />
              </div>

              <div className="foreground">
                <p>{key}</p>
              </div>
            </Card>
            <Button
              type="primary"
              onClick={() => onSelect(key)}
              style={{fontWeight: 'bold'}}
            >
              Découvrir
            </Button>
          </div>
        </Col>
      ))}
      <LyonAdditionalInfo />
    </Row>
  );
};

export default memo(ProtectionProducts);

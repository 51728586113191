import {ReactNode} from 'react';
import {
  BaseQuoteData,
  BaseQuoteLine,
  DEAFNESS_LABEL,
  fabricationMethodRule,
  Option,
  Product,
  TBoolean,
  transferFabricationMethod,
  TransferMethod,
} from '../../contexts/Quote';
import {User, WakandaService} from '../../util/fetch-wakanda';
import {
  SELECTED_EARS_EMOJIS,
  SELECTED_EARS_SHORT_TEXT,
} from '../../util/render';
import {
  classiqueMaterialPrefix,
  classiqueMaterialRule,
  classiqueShapeRule,
  CLASSIQUE_MATERIAL,
  CLASSIQUE_SHAPE,
  COLOR,
  colorLabels,
  cordColorRules,
  CORD_COLOR,
  deporteMaterialRule,
  deporteMicroRules,
  deporteNonMicroRules,
  deporteOptionsRules,
  deporteOptionsTypeEmbout,
  deporteShapeRule,
  DEPORTE_MATERIAL,
  DEPORTE_OPTION,
  DEPORTE_SHAPE,
  earbackColorationRules,
  earbackProductRules,
  EARBACK_COLORATION,
  EARBACK_PRODUCT,
  earBudRules,
  earWaxShieldRules,
  EARWAX_SHIELD,
  EAR_BUD,
  EVENT,
  eventRules,
  FILTER,
  FINISH,
  finishRules,
  MISCELLANEOUS,
  miscellaneousRules,
  OPTION,
  optionEmboutLabelRules,
  optionRules,
  optionTubeRules,
  OPTION_TUBE,
  passtopOsRules,
  PASSTOP_OS_PRODUCT_TYPE,
  PRODUCT_CATEGORY,
  PROTECTION_SHAPE,
  shapeLabels,
  SURFACE,
  surfaceRules,
  TUBE,
  tubeRules,
  TYPE,
  EMOP067_RULE,
  PLATINUM_25_SH_EMOP067,
  PLATINUM_40_SH_EMOP067,
} from '../Options/data';
import {getSelectedEars, SELECTED_EARS} from '../Options/static';
import {Details, Order, OREILLE} from '../Orders/data';

const {REACT_APP_OFFLINE_MODE} = process.env;

function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

interface Bague {
  marque: string;
  ecouteur: string;
  id: string;
  qty: number;
  selected: TBoolean;
}

export interface Article {
  ID: string;
  code: string;
  regle: string;
  libelle: string;
  miniCde: number;
  id_famille: string;
  id_sousFamille: string;
  nonRemisable: boolean;
  uniteVente: string;
  moisGarantie: number;
  prix?: number | {key: number; value: number}[];
  remise?: number | {key: number; value: number}[];
  bagues?: {bagues: Bague[]};
  delaiFabAM: string;
  delaiFabPM: string;
  paire: true | null;
  empreintePhysique: boolean | null;
}

export interface ArticleListItem {
  ID: string;
  key: number;
  num: number;
  code: string;
  libelle: string;
  oreille: ReactNode;
  qty: number;
  unit: string;
  totalHT: number;
  selected?: TBoolean;
  unitPrice?: number;
  discount?: number;
  netPrice?: number;
  totalPrice?: number;
  bagues?: {bagues: Bague[]};
  delaiFabAM: number;
  delaiFabPM: number;
  paire: true | null;
  empreintePhysique: boolean;
}

interface TempOrder {
  clientID?: string;
  TRP: string;
  dateLivrSouh: number;
  message: string;
  livr_id: string;
  livr_rs: string;
  livr_adr: string;
  livr_cp: string;
  livr_ville: string;
  livr_pays: string;
  contact: string;
  email: string;
  details: Details[];
  ATT_attente: boolean;
  ATT_motif?: string;
  noBoite?: string;
  modeEmpreinte: number;
  numeroCommandeEmpreintesPrec?: string;
  numAffaire?: string;
}

const getOptionRule = (baseRule: string, type: TYPE, name: string): string => {
  if (name === '') {
    return '';
  }

  let rule = baseRule;

  switch (type) {
    case TYPE.EVENT_TYPE_AND_SIZE:
    case TYPE.EVENT:
      const eventRule = eventRules[name as EVENT];
      if (!eventRule) {
        return '';
      }

      if (eventRule.startsWith('/') && eventRule.endsWith(':/')) {
        return eventRule;
      }

      rule += eventRule;
      break;
    case TYPE.SURFACE:
      const surfaceRule = surfaceRules[name as SURFACE];
      if (!surfaceRule) {
        return '';
      }

      rule += surfaceRule;
      break;
    case TYPE.TUBE:
      const tubeRule = tubeRules[name as TUBE];
      if (!tubeRule) {
        return '';
      }

      if (tubeRule.startsWith('/') && tubeRule.endsWith(':/')) {
        return tubeRule;
      }

      rule += tubeRule;
      break;
    case TYPE.OPTION_TUBE:
      const optionTubeRule = optionTubeRules[name as OPTION_TUBE];
      if (!optionTubeRule) {
        return '';
      }

      break;
    case TYPE.EARWAX_SHIELDING:
      const earwaxShieldingRule = earWaxShieldRules[name as EARWAX_SHIELD];
      if (!earwaxShieldingRule) {
        return '';
      }

      return earwaxShieldingRule;
    case TYPE.MISCELLANEOUS:
      const miscellaneousRule = miscellaneousRules[name as MISCELLANEOUS];
      if (!miscellaneousRule) {
        return '';
      }

      rule += miscellaneousRule;
      break;
    case TYPE.EAR_BUD_ASSEMBLY:
      rule += 'OPT_montage';
      break;
    case TYPE.FLOAT:
    case TYPE.CAMO:
      rule += 'OPT_option01';
      break;
    case TYPE.CORD:
      rule += 'OPT_aquastopC';
      break;
    case TYPE.OPTION:
      const optionRule = optionRules[name as OPTION];
      if (!optionRule) {
        return '';
      }

      if (optionRule.startsWith('/') && optionRule.endsWith(':/')) {
        return optionRule;
      }

      rule += optionRule;
      break;
    /* case TYPE.CORD_COLOR:
      const cordColorRule = cordColorRules[name as CORD_COLOR];
      if (!cordColorRule) {
        return '';
      }

      rule += cordColorRule;
      break; */

    case TYPE.DEPORTE_OPTION:
      return deporteOptionsRules[name as DEPORTE_OPTION]
        ? (deporteOptionsRules[name as DEPORTE_OPTION] as string)
        : '';
    case TYPE.FINISH:
      return finishRules[name as FINISH]
        ? (finishRules[name as FINISH] as string)
        : '';
    case TYPE.ENGRAVING:
      rule += 'OPT_option06';
      break;
    case TYPE.COLOR:
      if (earbackColorationRules[name as EARBACK_COLORATION]) {
        return earbackColorationRules[name as EARBACK_COLORATION];
      }

      switch (name as COLOR) {
        case COLOR.PASSTOP_OR_BLUE:
          rule += 'OPT_Couleur:/EMB_EPR_EBT_autre';
          break;

        default:
          return '';
      }
      break;
    case TYPE.CORD_COLOR:
      const cordColorRule = cordColorRules[name as CORD_COLOR];
      if (!cordColorRule) {
        return '';
      }

      return cordColorRule;
    case TYPE.EARBACK_PRODUCT:
      return earbackProductRules[name as EARBACK_PRODUCT];
    default:
      return '';
  }

  return rule + ':/';
};

const readPriceDiscount = (
  art: Article,
  qty: number,
  type: 'prix' | 'remise',
): number => {
  const price = art[type];

  if (!price) {
    return 0;
  }

  if (typeof price === 'number') {
    return price;
  }

  if (Array.isArray(art[type]) && price.length > 0) {
    if (qty <= art.miniCde) {
      // Take the minimum
      return price[0].value;
    }

    let best = 0;
    let cpt = 0;
    while (price.length > cpt && price[cpt].key <= qty) {
      best = price[cpt].value;
      cpt++;
    }

    return best;
  }

  return 0;
};

const getShape = (
  line: BaseQuoteLine,
  selected: TBoolean,
): Option | undefined =>
  line.options.selected.find(
    (opt) =>
      opt.type === TYPE.DEPORTE_BRAND &&
      ((opt.selected[0] && opt.selected[0] === selected[0]) ||
        (opt.selected[1] && opt.selected[1] === selected[1])),
  );

const getShapeRule = (
  baseRule: string,
  line: BaseQuoteLine,
  selected: TBoolean,
): string => {
  if (baseRule === '/EMB_EPR_') {
    baseRule += 'EBT_';
  }

  const shape = getShape(line, selected);
  if (!shape) {
    return '';
  }

  let shapeRule = baseRule;

  switch (shape.name) {
    // TODO
    /* case DEPORTE_SHAPE.CANULE:
      shapeRule += 'FRM_canule';
      break;
    case DEPORTE_SHAPE.EPAULEMENT_COURT:
    case DEPORTE_SHAPE.EPAULEMENT_MOYEN:
    case DEPORTE_SHAPE.EPAULEMENT_LONG:
      shapeRule += 'FRM_canuleEpaule';
      break;
    case DEPORTE_SHAPE.COQUILLE:
      shapeRule += 'FRM_coquille';
      break;
    case DEPORTE_SHAPE.DEMI_CONQUE:
      shapeRule += 'FRM_demiConque';
      break;
    case DEPORTE_SHAPE.PHANTOMOLD:
      shapeRule += 'FRM_phantomold';
      break;
    case DEPORTE_SHAPE.SILOUETTE:
      shapeRule += 'FRM_silhouette';
      break; */
    default:
      shapeRule = '';
      throw new Error('unknown shape rule');
  }

  return shapeRule + ':/';
};

const getMaterialRules = (
  method: TransferMethod,
  baseRule: string,
  line: BaseQuoteLine,
  microSelected: TBoolean,
): {selected: TBoolean; rule: string}[] => {
  if (baseRule === '/EMB_EPR_') {
    baseRule += 'EBT_';
  }

  const materials = line.options.selected.filter(
    (opt) => opt.type === TYPE.MATERIAL,
  );

  const methodPrefix = fabricationMethodRule[transferFabricationMethod[method]];

  return (materials.map((material) => {
    let micro = false;

    for (const index in material.selected) {
      if (microSelected[index] === true && material.selected[index] === true) {
        micro = true;
      }
    }

    if (micro === true) {
      baseRule = '/EMB_MCT_';
    }

    let materialRule = methodPrefix + baseRule;

    switch (material.name) {
      // TODO
      /* case DEPORTE_CANULE_MATERIAL.RESINE_DURE:
        materialRule +=
          'MAT_dure:' + getShapeRule(baseRule, line, material.selected);
        break;
      case DEPORTE_CANULE_MATERIAL.SILICON_70:
        materialRule +=
          'MAT_silicone70:' + getShapeRule(baseRule, line, material.selected);
        break;
      case DEPORTE_CANULE_MATERIAL.THERMOTEC:
        materialRule +=
          'MAT_thermotec:' + getShapeRule(baseRule, line, material.selected);
        break; */

      default:
        // FIXME
        materialRule = '';
        return undefined;
    }

    return {selected: material.selected, rule: materialRule};
  }) as {selected: TBoolean; rule: string}[]).filter((rule) => rule);
};

export const getBaseRule = (line: BaseQuoteLine): string => {
  if (!line.category?.name) {
    return '';
  }

  let baseRule = '/EMB_';

  switch (line.category.name) {
    case PRODUCT_CATEGORY.CLASSIQUE:
      baseRule += 'RES_';
      break;
    case PRODUCT_CATEGORY.DEPORTE:
      baseRule += 'MCE_';
      break;
    case PRODUCT_CATEGORY.PROTECTION:
      baseRule += 'EPR_';
      break;
  }

  return baseRule;
};

const getProductRules = (
  method: TransferMethod,
  baseRule: string,
  product: Product,
  selected: TBoolean,
  options: Option[],
): {selected: TBoolean; rule: string}[] => {
  if (baseRule === '/EMB_EPR_') {
    baseRule += 'EBT_';
  }

  const rules: {selected: TBoolean; rule: string}[] = [];

  const methodPrefix = fabricationMethodRule[transferFabricationMethod[method]];

  let rule = /* methodPrefix +  */ baseRule;

  if (
    getStringEnumValues(CLASSIQUE_SHAPE).includes(
      product.name as CLASSIQUE_SHAPE,
    )
  ) {
    const materials = options.filter(({type}) => type === TYPE.MATERIAL);
    for (const material of materials) {
      const shapeRule =
        typeof classiqueShapeRule[product.name as CLASSIQUE_SHAPE] === 'string'
          ? classiqueShapeRule[product.name as CLASSIQUE_SHAPE]
          : (classiqueShapeRule[product.name as CLASSIQUE_SHAPE] as Function)(
              material?.name as CLASSIQUE_MATERIAL,
            );

      const materialPrefix =
        classiqueMaterialPrefix[material?.name as CLASSIQUE_MATERIAL] || '';
      let materialRule = classiqueMaterialRule[
        material?.name as CLASSIQUE_MATERIAL
      ](shapeRule, product.name as CLASSIQUE_SHAPE);

      if (materialRule.endsWith('canulePinceCrabe:/'))
        materialRule = materialRule.replace(/Crabe:\/$/, ':/');

      if (materialRule.startsWith('/')) {
        rule = materialRule;
      } else {
        rule = `/EMB_${materialPrefix}_MAT_${materialRule}:/EMB_${materialPrefix}_FRM_${shapeRule}:/`;
      }

      // if the material is "PLATINUM_25_SH" or "PLATINUM_40_SH",
      // and if the user has selected some specific colors, we must add
      // another article to order ("EMOP067_RULE")
      if (
        [
          CLASSIQUE_MATERIAL.PLATINUM_25_SH,
          CLASSIQUE_MATERIAL.PLATINUM_40_SH,
        ].includes(material?.name as CLASSIQUE_MATERIAL)
      ) {
        let colorRule:
          | {
              selected: TBoolean;
              rule: string;
            }
          | undefined = undefined;

        const colorOptions = options.filter((opt) => opt.type === TYPE.COLOR);
        for (const colorOption of colorOptions) {
          const colorName = colorOption.name as COLOR;

          if (
            PLATINUM_25_SH_EMOP067.includes(colorName) ||
            PLATINUM_40_SH_EMOP067.includes(colorName)
          ) {
            if (!colorRule) {
              colorRule = {rule: EMOP067_RULE, selected: [false, false]};
            }

            colorRule.selected[0] =
              colorRule.selected[0] || colorOption.selected[0];
            colorRule.selected[1] =
              colorRule.selected[1] || colorOption.selected[1];
          }
        }

        if (colorRule) {
          rules.push(colorRule);
        }
      }

      rules.push({selected: material.selected, rule});
    }
  } else if (
    getStringEnumValues(DEPORTE_SHAPE).includes(product.name as DEPORTE_SHAPE)
  ) {
    const shape = options.find(({type}) => type === TYPE.SHAPE) as Option;
    const materials = options.filter(({type}) => type === TYPE.MATERIAL);
    for (const material of materials) {
      const micro = options.find(
        ({type, name}) =>
          type === TYPE.EAR_BUD && [EAR_BUD.MICRO_3].includes(name as EAR_BUD),
      );

      {
        const shapeRules = (micro?.selected
          ? deporteMicroRules
          : deporteNonMicroRules)[shape.name as DEPORTE_SHAPE];
        const materialRule =
          shapeRules && shapeRules[material?.name as DEPORTE_MATERIAL];

        if (materialRule) {
          rules.push({selected: material.selected, rule: materialRule});
          continue;
        }
      }

      let shapeRule = deporteShapeRule[shape.name as DEPORTE_SHAPE];
      const materialRule =
        deporteMaterialRule[material?.name as DEPORTE_MATERIAL];

      if (micro?.selected) {
        shapeRule = shapeRule?.replace(/^DPT_/, 'AER_');
      }

      rule = `/EMB_MCE_MAT_${materialRule}:/EMB_MCE_FRM_${shapeRule}:/`;

      rules.push({selected: material.selected, rule});
    }
  } else {
    switch (product.name) {
      case PROTECTION_SHAPE.SOMMEIL:
        rule += 'sommeil:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.SWEETNIGHT:
        rule += 'sweetnight:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.SWEETNIGHT_CANULE:
        rule += 'sommeil:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.AQUASTOP:
        rule += 'aquastop:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.OBTURATEUR:
        rule += 'obt40:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.GAME_EAR:
        rule = '/EMB_ATR_EAR_gamePro:/EMB_ATR_COM_gameEar:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PASSTOP_OR:
        const typeOptions = options.filter(
          (opt) => opt.type === TYPE.PASSTOP_OR_PRODUCT_TYPE,
        );
        for (const typeOption of typeOptions) {
          rules.push({
            selected: typeOption.selected,
            rule: rule + `passtopO${typeOption.name}:/`,
          });
        }
        break;
      case PROTECTION_SHAPE.AQUASON:
        rule = '/EMB_EPR_EBT_aquason:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PASSTOP_EP2:
        const passtopColorRules: {[color in COLOR]?: string} = {
          [COLOR.BLACK_OPAQUE]: '/EMB_EPR_EBT_passtopEP2n:/',
          [COLOR.GREEN_TRANSLUCENT]: '/EMB_EPR_EBT_passtopEP2v:/',
          [COLOR.BLUE_TRANSLUCENT]: '/EMB_EPR_EBT_passtopEP2b:/',
          [COLOR.COLORLESS_TRANSLUCENT]: '/EMB_EPR_EBT_passtopEP2tr:/',
        };

        const colorOptions = options.filter((opt) => opt.type === TYPE.COLOR);
        for (const colorOption of colorOptions) {
          if (passtopColorRules[colorOption.name as COLOR]) {
            rules.push({
              selected: colorOption.selected,
              rule: passtopColorRules[colorOption.name as COLOR] as string,
            });
          }
        }
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PIANISSIMO: {
        const filterOptions = options.filter((opt) => opt.type === TYPE.FILTER);
        filterOptions.map((opt): void => {
          let optRule = rule;
          switch (opt.name) {
            case FILTER.DB_10:
              optRule += 'pianissimo10:/';
              break;
            case FILTER.DB_15:
              optRule += 'pianissimo15:/';
              break;
            case FILTER.DB_25:
              optRule += 'pianissimo25:/';
              break;
            case FILTER.DB_30:
              optRule += 'pianissimo30:/';
              break;
          }
          rules.push({
            selected: opt.selected,
            rule: optRule,
          });

          return undefined;
        });
        break;
      }
      /* case PROTECTION_SHAPE.EAR:
      rule += 'earMonitor:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.AQUASON:
      rule += 'aquason:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.AQUASTOP:
      rule += 'aquastop:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.PASSTOP_EP2:
      rule += 'passtopEP2:/';
      rules.push({selected, rule});
      break;
    case PROTECTION_SHAPE.PIANISSIMO: {
      const filterOptions = options.filter((opt) => opt.type === TYPE.FILTER);
      filterOptions.map((opt): void => {
        let optRule = rule;
        switch (opt.name) {
          case FILTER.DB_15:
            optRule += 'filtre15:/';
            break;
          case FILTER.DB_25:
            optRule += 'filtre25:/';
            break;
          case FILTER.DB_30:
            optRule += 'filtre30:/';
            break;
        }
        rules.push({
          selected: opt.selected,
          rule: optRule,
        });

        return undefined;
      });
      break;
    }
    case PROTECTION_SHAPE.PASSTOP_T:
      {
        const filterOptions = options.filter((opt) => opt.type === TYPE.FILTER);
        filterOptions.map((opt): void => {
          let optRule = rule;
          switch (opt.name) {
            case FILTER.SNR22:
              optRule += 'T3:/';
              break;
            case FILTER.SNR24:
              optRule += 'T4:/';
              break;
            case FILTER.SNR21:
              optRule += 'C2:/';
              break;
            case FILTER.SNR26:
              optRule += 'C3:/';
              break;
          }
          rules.push({
            selected: opt.selected,
            rule: optRule,
          });

          return undefined;
        });
      }
      break;
    case PROTECTION_SHAPE.SOMMEIL:
      const shapeOptions = options.filter((opt) => opt.type === TYPE.SHAPE);
      shapeOptions.map((opt): void => {
        let optRule = rule;
        switch (opt.name) {
          case PROTECTION_SOMMEIL_SHAPE.CONQUE:
            optRule += 'conque:/';
            break;
          case PROTECTION_SOMMEIL_SHAPE.CANULE:
            optRule += 'canule:/';
            break;
        }
        rules.push({
          selected: opt.selected,
          rule: optRule,
        });

        return undefined;
      });
      break; */
      case PROTECTION_SHAPE.PASSTOP_A:
        rule = '/EMB_EPR_EBT_passtopAn:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PASSTOP_TIR_CHASSE:
        rule = '/EMB_EPR_EBT_passtopTirOS:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PASSTOP_CANULE_TIR_CHASSE:
        rule = '/EMB_EPR_EBT_passtopTirOSC:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.STOPGUN_E:
        if (selected[0]) {
          rules.push({
            selected: [true, false],
            rule: '/EMB_ATR_PTR_stopgunEClassicG:/',
          });
        }
        if (selected[1]) {
          rules.push({
            selected: [false, true],
            rule: '/EMB_ATR_PTR_stopgunEClassicD:/',
          });
        }
        break;
      case PROTECTION_SHAPE.STOPGUN_FLEX:
        rule = '/EMB_ATR_PTR_stopgunF:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PASSTOP_STOPGUN:
        rule = '/EMB_EPR_EBT_stopgun:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.OREILLETTE:
        rule = '/EMB_ATR_COM_oreillette:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.PASSTOP_OS: {
        const product = options.filter(
          (opt) => opt.type === TYPE.PASSTOP_OS_PRODUCT_TYPE,
        );
        product.map((opt): void => {
          rules.push({
            selected: opt.selected,
            rule: passtopOsRules[opt.name as PASSTOP_OS_PRODUCT_TYPE],
          });

          return undefined;
        });
        break;
      }
      case PROTECTION_SHAPE.PASSTOP_OS_CONQUE:
        rule = '/EMB_EPR_EBT_autre:/EMB_ATR_COM_passtopOS:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.OBTURATEUR_COM:
        rule = '/EMB_ATR_autre:/IND_PST_Autre:/EMB_ATR_COM_obt:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.SECRETEAR:
        rule = '/EMB_ATR_autre:/IND_PST_Autre:/EMB_ATR_COM_secret:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.EAR:
        rule = '/EMB_ATR_COM_adaptateur:/IND_PST_Autre:/';
        rules.push({selected, rule});
        break;
      case PROTECTION_SHAPE.EARBACK_MUSIC: {
        const product = options.filter(
          (opt) => opt.type === TYPE.EARBACK_PRODUCT,
        );
        product.map((opt): void => {
          let optRule = rule;
          switch (opt.name) {
            case EARBACK_PRODUCT.ONE:
              optRule = '/EMB_ATR_EAR_voie1:/';
              break;
            case EARBACK_PRODUCT.TWO:
              optRule = '/EMB_ATR_EAR_voie2:/';
              break;
            case EARBACK_PRODUCT.THREE:
              optRule = '/EMB_ATR_EAR_autre:/EMB_ATR_EAR_voie3:/';
              break;
            case EARBACK_PRODUCT.THREE_T:
              optRule = '/EMB_ATR_EAR_sBass:/';
              break;
            case EARBACK_PRODUCT.FOUR_T:
              optRule = '/EMB_ATR_EAR_autre:/EMB_ATR_EAR_sBass4:/';
              break;
          }
          rules.push({
            selected: opt.selected,
            rule: optRule,
          });

          return undefined;
        });

        const colorOptions = options.filter(
          (opt) => opt.type === TYPE.EARBACK_COLORATION,
        );
        for (const colorOption of colorOptions) {
          if (earbackColorationRules[colorOption.name as EARBACK_COLORATION]) {
            rules.push({
              selected: colorOption.selected,
              rule: earbackColorationRules[
                colorOption.name as EARBACK_COLORATION
              ] as string,
            });
          }
        }

        break;
      }
      default:
        throw new Error('unkown shape');
    }
  }

  return rules;
};

export const getArticles = async (
  quote: BaseQuoteData,
  _line: BaseQuoteLine,
  session: WakandaService,
  user: User,
): Promise<BaseQuoteLine | null> => {
  const line = Object.assign({}, _line);
  if (!line.category || !line.product) {
    return null;
  }

  const rules: string[] = [];
  const productRules: {selected: TBoolean; rule: string}[] = [];

  /* const micro = line.options.selected.find(
    ({type, name}) =>
      type === TYPE.EAR_BUD &&
      [
        EAR_BUD.MICRO,
        EAR_BUD.CORDA_SQUARED,
        EAR_BUD.MINI_FIT_CORDA,
        EAR_BUD.MICRO_TUBE_ELAN_13,
        EAR_BUD.MICRO_TUBE_M_10,
        EAR_BUD.MICRO_TUBE_EASYWEAR_09_16,
        EAR_BUD.M_TUBE,
      ].includes(name as EAR_BUD),
  ); */

  const baseRule = getBaseRule(line);

  productRules.push(
    ...getMaterialRules(
      quote.transfer.method,
      baseRule,
      line,
      /*  micro?.selected ||  */ [false, false],
    ),
  );

  if (!productRules.length) {
    productRules.push(
      ...getProductRules(
        quote.transfer.method,
        baseRule,
        line.product,
        line.options.ears,
        line.options.selected,
      ),
    );
  }

  rules.push(...productRules.map(({rule}) => rule));

  const optionsRulesMap: {[key: number]: string} = {};
  line.options.selected.map((opt, index): void => {
    let microSelected = false;
    for (const index in [0, 1]) {
      if (
        /* micro ||  */ {selected: [false, false]}.selected[index] ===
          opt.selected[index] &&
        opt.selected[index] === true
      ) {
        microSelected = true;
      }
    }

    if (microSelected) {
      const rule = getOptionRule('/EMB_MCT_', opt.type, opt.name);
      if (!rule) {
        return undefined;
      }
      optionsRulesMap[index] = rule;
      if (!rules.includes(rule)) {
        rules.push(rule);
      }
      return undefined;
    }

    const rule = getOptionRule(baseRule, opt.type, opt.name);
    if (!rule) {
      return undefined;
    }
    optionsRulesMap[index] = rule;
    if (!rules.includes(rule)) {
      rules.push(rule);
    }
    /* if (!micro) {
      return undefined;
    } */
    return undefined;
  });

  const filteredRules = rules.filter((rule) => rule);

  if (filteredRules.length === 0) {
    return null;
  }

  line.articles = [];
  line.total = 0;
  const articles = (
    (await session.fetch<Article[]>('Articles', 'getArticlesFromRegles', {
      rules: filteredRules,
      clientId: quote.clientId,
    })) || []
  )
    // enforces strict rule validation
    .filter(({regle}) => rules.includes(regle));

  if (articles.length === 0) {
    return null;
  }

  let totalPrice = 0;

  const productArticles = articles.filter(
    (art) =>
      productRules.filter(({rule}) => art.regle.includes(rule)).length > 0,
  );

  const bagues: Bague[] = [];

  // eslint-disable-next-line array-callback-return
  productArticles.map((article, index): void => {
    const opt = productRules.find(({rule}) => rule === article.regle);
    if (!opt) {
      return undefined;
    }

    const qty = Math.min(
      opt.selected.filter((ear) => ear === true).length,
      article.paire ? 1 : 2,
    );

    const art: ArticleListItem = {
      ID: article.ID,
      bagues: article.bagues,
      key: index,
      num: index + 1,
      code: article.code,
      libelle: article.libelle,
      selected: opt.selected,
      oreille: SELECTED_EARS_SHORT_TEXT[getSelectedEars(opt.selected)],
      qty,
      unit: article.uniteVente,
      totalHT: readPriceDiscount(article, qty, 'prix'),
      delaiFabAM: Number(article.delaiFabAM),
      delaiFabPM: Number(article.delaiFabPM),
      paire: article.paire,
      empreintePhysique: !!article.empreintePhysique,
    };

    if (user && user.blChiffre) {
      const price = art.totalHT;
      const discount =
        (quote.warranty ? 100 : readPriceDiscount(article, art.qty, 'remise')) /
        100;
      const netPrice = price * (1 - discount);

      art.unitPrice = price;
      art.discount = discount;
      art.netPrice = netPrice;
      art.totalPrice = netPrice * art.qty;

      totalPrice += art.totalPrice;
    }

    if (!line.articles) {
      line.articles = [];
    }

    line.articles.push(art);

    if (
      article.bagues &&
      article.bagues.bagues &&
      article.bagues.bagues.length > 0
    ) {
      const artBagues = article.bagues.bagues;
      const marque =
        (line.category?.name === PRODUCT_CATEGORY.DEPORTE &&
          line.product?.label) ||
        null;
      opt.selected.map((sel, idx): void => {
        if (!sel) {
          return undefined;
        }

        const ecouteur = getLabel(getOption(line, idx, TYPE.EAR_BUD)?.label);

        const bague = artBagues.find(
          (bague) =>
            bague.marque.toLowerCase() === marque?.toLowerCase() &&
            bague.ecouteur.toLowerCase() === ecouteur?.toLowerCase(),
        );

        if (bague) {
          bagues.push({...bague, qty, selected: opt.selected});
        }

        return undefined;
      });
    }
  });

  let idx = line.articles.length;

  line.articles.push(
    ...(Object.keys(optionsRulesMap)
      .map((key: string, num: number): ArticleListItem | undefined => {
        const index = parseInt(key);
        const opt = line.options.selected[index];
        const rule = optionsRulesMap[index];
        const article = articles.find((art) => art.regle.includes(rule));

        if (!article || line.articles?.find((art) => art.ID === article.ID)) {
          return undefined;
        }

        const qty = Math.min(
          opt.selected.filter((sel) => sel === true).length,
          article.paire ? 1 : 2,
        );

        const art: ArticleListItem = {
          ID: article.ID,
          bagues: article.bagues,
          key: idx + num,
          num: idx + num + 1,
          code: article.code,
          libelle: article.libelle,
          oreille: SELECTED_EARS_SHORT_TEXT[getSelectedEars(opt.selected)],
          qty,
          unit: article.uniteVente,
          totalHT: readPriceDiscount(article, qty, 'prix'),
          delaiFabAM: Number(article.delaiFabAM),
          delaiFabPM: Number(article.delaiFabPM),
          paire: article.paire,
          empreintePhysique: !!article.empreintePhysique,
        };

        if (user && user.blChiffre) {
          const price = art.totalHT;
          const discount =
            (quote.warranty
              ? 100
              : readPriceDiscount(article, art.qty, 'remise')) / 100;
          const netPrice = price * (1 - discount);

          art.unitPrice = price;
          art.discount = discount;
          art.netPrice = netPrice;
          art.totalPrice = netPrice * art.qty;

          totalPrice += art.totalPrice;
        }

        return art;
      })
      .filter((art) => art) as ArticleListItem[]),
  );

  idx = line.articles.length;

  const bagueArtItems: ArticleListItem[] = articles
    .filter((article) => bagues.find((bague) => bague.id === article.ID))
    .map((article, num: number) => {
      const bague = bagues.find((bague) => bague.id === article.ID);
      if (!bague) {
        return undefined;
      }

      const qty = Math.min(bague.qty, article.paire ? 1 : 2);

      const art: ArticleListItem = {
        ID: article.ID,
        bagues: article.bagues,
        key: idx + num,
        num: idx + num + 1,
        code: article.code,
        libelle: article.libelle,
        oreille: SELECTED_EARS_EMOJIS[getSelectedEars(bague.selected)],
        qty,
        unit: article.uniteVente,
        totalHT: readPriceDiscount(article, qty, 'prix'),
        delaiFabAM: Number(article.delaiFabAM),
        delaiFabPM: Number(article.delaiFabPM),
        paire: article.paire,
        empreintePhysique: !!article.empreintePhysique,
      };

      if (user && user.blChiffre) {
        const price = art.totalHT;
        const discount =
          (quote.warranty
            ? 100
            : readPriceDiscount(article, art.qty, 'remise')) / 100;
        const netPrice = price * (1 - discount);

        art.unitPrice = price;
        art.discount = discount;
        art.netPrice = netPrice;
        art.totalPrice = netPrice * art.qty;

        totalPrice += art.totalPrice;
      }

      return art;
    }) as ArticleListItem[];

  line.articles.push(...bagueArtItems);

  line.total = totalPrice;

  return line;
};

const getLabel = (label: string | undefined): string | null => {
  if (!label) {
    return null;
  }

  return label;
};

const getOptions = (
  line: BaseQuoteLine,
  index: number,
  optType: TYPE,
): Option[] | undefined => {
  const opts = line.options.selected.filter(
    ({type, selected}) => type === optType && selected[index],
  );

  return opts;
};

const getOption = (
  line: BaseQuoteLine,
  index: number,
  optType: TYPE,
  optName?: string,
): Option | undefined => {
  const opt = line.options.selected.find(
    ({name, type, selected}) =>
      (!optName || name === optName) && type === optType && selected[index],
  );

  return opt;
};

const getOrderDetails = (quote: BaseQuoteData): Details[] => {
  return quote.lines.map((line) => {
    let OREILLEStr = '';
    const ears = getSelectedEars(line.options.ears);
    switch (ears) {
      case SELECTED_EARS.BOTH:
        OREILLEStr = 'OGOD';
        break;
      case SELECTED_EARS.LEFT_ONLY:
        OREILLEStr = 'OG';
        break;
      case SELECTED_EARS.RIGHT_ONLY:
        OREILLEStr = 'OD';
        break;
    }

    const getOreille = (index: number): OREILLE | null => {
      if (!line.options.ears[index]) {
        return null;
      }

      const getSpecificOption = (
        optType: TYPE,
        name: string,
      ): Option | undefined => {
        const opt = getOption(line, index, optType, name);

        if (!opt || opt.name !== name) {
          return;
        }

        return opt;
      };

      const marque =
        (line.category?.name === PRODUCT_CATEGORY.DEPORTE &&
          line.product?.label) ||
        null;
      const ecouteurOption = getOption(line, index, TYPE.EAR_BUD);
      const ecouteur = ecouteurOption
        ? (earBudRules[ecouteurOption.name as EAR_BUD] ||
            getLabel(getOption(line, index, TYPE.EAR_BUD)?.label)) ??
          null
        : null;

      let article = null;
      let bague = null;
      if (line.articles && line.articles.length > 0) {
        const art = line.articles.find(
          (arti) => arti.selected && arti.selected[index] === true,
        );
        if (!art) {
          return null;
        }
        article = art.ID;

        if (art.bagues && art.bagues.bagues && art.bagues.bagues.length > 0) {
          const artBagues = art.bagues.bagues;
          const artBague = artBagues.find(
            (bague) =>
              bague.marque.toLowerCase() === marque?.toLowerCase() &&
              bague.ecouteur.toLowerCase() === ecouteur?.toLowerCase(),
          );
          if (artBague) {
            bague = artBague.id;
          }
        }
      }

      const baseRule = getBaseRule(line);

      let forme = getLabel(getOption(line, index, TYPE.SHAPE)?.label);
      let formeEpaulement = null;
      if (forme && forme.includes('Épaulement')) {
        const formeTokens = forme.split(' ');
        forme = formeTokens[0];
        formeEpaulement = formeTokens[1];
      }
      const longueurEpaulement = getLabel(
        getOption(line, index, TYPE.SHOULDER)?.label,
      );
      if (longueurEpaulement && longueurEpaulement.includes('Épaulement')) {
        const longueurEpaulementTokens = longueurEpaulement.split(' ');
        formeEpaulement = longueurEpaulementTokens[1];
      }

      let event = null;
      let eventDiam = null;
      let eventSurMesure = null;
      const eventOpts = [
        ...(getOptions(line, index, TYPE.EVENT) || []),
        ...(getOptions(line, index, TYPE.EVENT_TYPE_AND_SIZE) || []),
      ];
      for (const eventOpt of eventOpts) {
        if (eventOpt.label.includes(' mm')) {
          eventDiam = eventOpt.label.split(' ')[0];
        } else if (eventOpt.name === EVENT.MAX) {
          eventDiam = 'MX';
        } else if (eventOpt.name === EVENT.CUSTOM && eventOpt.value) {
          eventSurMesure = eventOpt.value[index]?.toString() || null;
        } else {
          event = getOptionRule(baseRule, TYPE.EVENT, eventOpt.name);
        }
      }

      let tubeCouleur = null;
      const tubeOpt = getOption(line, index, TYPE.TUBE, TUBE.COLORED);
      if (tubeOpt && tubeOpt.value?.[index]) {
        tubeCouleur = colorLabels[tubeOpt.value[index] as COLOR];
      }

      let customMark = null;

      if (
        [
          PROTECTION_SHAPE.AQUASON,
          PROTECTION_SHAPE.PASSTOP_EP2,
          PROTECTION_SHAPE.EARBACK_MUSIC,
        ].includes(line.product?.name as PROTECTION_SHAPE)
      ) {
        const customMarkOpt = getOptions(
          line,
          index,
          TYPE.OPTION,
        )?.find((opt) =>
          [OPTION.LASER_MARK, OPTION.CORD_MARK].includes(opt.name as OPTION),
        );
        if (customMarkOpt && customMarkOpt.value?.[index]) {
          customMark = customMarkOpt.value[index] as string;
        }
      } else if (
        [PROTECTION_SHAPE.EAR, PROTECTION_SHAPE.OREILLETTE].includes(
          line.product?.name as PROTECTION_SHAPE,
        )
      ) {
        customMark = quote.customMarkFields?.join(' / ') || '';
      } else {
        const customMarkOpt = getOptions(
          line,
          index,
          TYPE.DEPORTE_OPTION,
        )?.find((opt) => opt.name === DEPORTE_OPTION.CUSTOM_MARK);
        if (customMarkOpt && customMarkOpt.value?.[index]) {
          customMark = customMarkOpt.value[index] as string;
        }
      }

      let optionsEcouteur: string[] | null = [];
      const extractionCord = getOptionRule(
        baseRule,
        TYPE.MISCELLANEOUS,
        getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.EXTRACTION_CORDS)
          ?.name || '',
      );
      if (extractionCord) {
        optionsEcouteur.push(extractionCord);
      }
      const earBudAssembly = getOptionRule(
        baseRule,
        TYPE.EAR_BUD_ASSEMBLY,
        getOption(line, index, TYPE.EAR_BUD_ASSEMBLY)?.name || '',
      );
      if (earBudAssembly) {
        optionsEcouteur.push(earBudAssembly);
      }
      const earwaxShielding = getOptionRule(
        baseRule,
        TYPE.EARWAX_SHIELDING,
        getOption(line, index, TYPE.EARWAX_SHIELDING)?.name || '',
      );
      if (earwaxShielding) {
        optionsEcouteur.push(earwaxShielding);
      }
      const groovedBlowhole = getOptionRule(
        baseRule,
        TYPE.MISCELLANEOUS,
        getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.GROOVED_EVENT)
          ?.name || '',
      );
      if (groovedBlowhole) {
        optionsEcouteur.push(groovedBlowhole);
      }
      if (optionsEcouteur.length === 0) {
        optionsEcouteur = null;
      }

      const [iros, halfIros] = [OPTION.IROS, OPTION.HALF_IROS].map((option) =>
        getOptionRule(
          baseRule,
          TYPE.OPTION,
          getSpecificOption(TYPE.OPTION, option)?.name || '',
        ),
      );

      const colorOption = getOptions(line, index, TYPE.COLOR)?.find(({name}) =>
        Object.keys(colorLabels).includes(name),
      );

      return {
        categorie: getLabel(line.category?.label),
        produit: line.product?.label || null,
        marque,
        matiere: getLabel(getOption(line, index, TYPE.MATERIAL)?.label),
        forme,
        article,
        bague,
        formeEpaulement,
        couleur: getLabel(colorOption?.label),
        finition: getLabel(
          getOption(line, index, TYPE.SURFACE)?.label ||
            getOption(line, index, TYPE.FINISH)?.label ||
            getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.SANDING)?.label,
        ),
        vernisAntibacterien:
          getOptionRule(
            baseRule,
            TYPE.SURFACE,
            getSpecificOption(TYPE.SURFACE, SURFACE.ANTIBACTERIAL)?.name || '',
          ) ||
          getOptionRule(
            baseRule,
            TYPE.MISCELLANEOUS,
            getSpecificOption(
              TYPE.MISCELLANEOUS,
              MISCELLANEOUS.ANTIBACTERIAL_VARNISH,
            )?.name || '',
          ) ||
          null,
        ecouteur,
        optionsEcouteur,
        tube:
          getOptionRule(
            baseRule,
            TYPE.TUBE,
            getOption(line, index, TYPE.TUBE)?.name || '',
          ) || null,
        optionEmbout: [
          ...(getOptions(line, index, TYPE.OPTION_TUBE)?.map((opt) =>
            getOptionRule(baseRule, TYPE.OPTION_TUBE, opt.name),
          ) || []),
          ...(Object.entries(optionEmboutLabelRules)
            .filter(([option]) => getSpecificOption(TYPE.OPTION, option))
            .map(([, value]) => value) as string[]),
        ],
        optionDeporte:
          (getStringEnumValues(DEPORTE_SHAPE).includes(
            line.product?.name as DEPORTE_SHAPE,
          ) &&
            getOptions(line, index, TYPE.DEPORTE_OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.DEPORTE_OPTION, opt.name),
            )) ||
          null,
        event,
        eventDiam,
        eventSurMesure,
        optionAquason:
          (line.product?.name === PROTECTION_SHAPE.AQUASON &&
            getOptionRule(
              baseRule,
              TYPE.OPTION,
              getOption(line, index, TYPE.OPTION)?.name || '',
            )) ||
          null,
        optionAquastop:
          (line.product?.name === PROTECTION_SHAPE.AQUASTOP &&
            getOptions(line, index, TYPE.OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.OPTION, opt.name),
            )) ||
          null,
        optionEP2:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_EP2 &&
            getOptions(line, index, TYPE.OPTION)
              ?.filter((op) => op.name === OPTION.CLOTHES_PIN)
              ?.map((opt) =>
                getOptionRule(baseRule, TYPE.OPTION, opt.name),
              )?.[0]) ||
          null,
        optionCordonEP2:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_EP2 &&
            getOptionRule(
              baseRule,
              TYPE.CORD_COLOR,
              getOption(line, index, TYPE.CORD_COLOR)?.name || '',
            )) ||
          null,
        optionGravureEP2:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_EP2 &&
            getOptions(line, index, TYPE.OPTION)
              ?.filter((op) => op.name === OPTION.CORD_MARK)
              ?.map((opt) =>
                getOptionRule(baseRule, TYPE.OPTION, opt.name),
              )?.[0]) ||
          null,
        optionPianissimo:
          (line.product?.name === PROTECTION_SHAPE.PIANISSIMO &&
            getOptions(line, index, TYPE.OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.OPTION, opt.name),
            )) ||
          null,
        optionPasstopT: null,
        optionTir: null,
        optionObturateur:
          (line.product?.name === PROTECTION_SHAPE.OBTURATEUR &&
            getOptions(line, index, TYPE.OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.OPTION, opt.name),
            )) ||
          null,
        optionPasstopO:
          ((line.product?.name === PROTECTION_SHAPE.PASSTOP_OR ||
            line.product?.name === PROTECTION_SHAPE.PASSTOP_OS) &&
            getOptions(line, index, TYPE.OPTION)
              ?.filter((opt) => opt.name !== OPTION.WITHOUT_HANDLE_PASTOP)
              .map((opt) => getOptionRule(baseRule, TYPE.OPTION, opt.name))) ||
          null,
        optionPasstopSansPoignee:
          ((line.product?.name === PROTECTION_SHAPE.PASSTOP_OR ||
            line.product?.name === PROTECTION_SHAPE.PASSTOP_OS) &&
            getOptions(line, index, TYPE.OPTION)?.find(
              (opt) => opt.name === OPTION.WITHOUT_HANDLE_PASTOP,
            )?.label) ||
          null,
        otoscan: false,
        /* otoscan:
          !line.articles?.find(({empreintePhysique}) => empreintePhysique) &&
          transferFabricationMethod[quote.transfer.method] ===
            FabricationMethod.TRADITIONAL, */
        repereCouleur:
          getOption(line, index, TYPE.COLOR_MARKER)?.selected[index] ||
          getSpecificOption(TYPE.MISCELLANEOUS, MISCELLANEOUS.COLOR_MARKER) !==
            undefined ||
          false,
        sousGarantie: quote.warranty || false,
        typeObturateur:
          line.product?.name === PROTECTION_SHAPE.OBTURATEUR
            ? getLabel(
                getOption(line, index, TYPE.OBTURATEUR_PRODUCT_TYPE)?.label,
              )
            : null,
        formeConque:
          line.product?.name === CLASSIQUE_SHAPE.DEMI_CONQUE ||
          forme === shapeLabels[DEPORTE_SHAPE.DEPORTE_HALF_CONQUE]
            ? 'demi'
            : null,
        optionIros: iros ? 'iros' : halfIros ? 'demiIros' : null,
        formeDeporte:
          line.product?.name &&
          getStringEnumValues(DEPORTE_SHAPE).includes(
            line.product?.name as DEPORTE_SHAPE,
          )
            ? line.product.name
            : null,
        tubeCouleur,
        deporteBrand:
          line.category?.name === PRODUCT_CATEGORY.DEPORTE
            ? (line.brand?.label as string)
            : null,
        typeEmbout:
          deporteOptionsTypeEmbout[
            getOptions(line, index, TYPE.DEPORTE_OPTION)?.find(
              (opt) => deporteOptionsTypeEmbout[opt.name as DEPORTE_OPTION],
            )?.name as DEPORTE_OPTION
          ] || null,
        customMark,
        earbackColorOption:
          earbackColorationRules[
            getOptions(line, index, TYPE.EARBACK_COLORATION)?.find(
              (opt) => earbackColorationRules[opt.name as EARBACK_COLORATION],
            )?.name as EARBACK_COLORATION
          ] || null,
        earbackOption:
          (line.product?.name === PROTECTION_SHAPE.EARBACK_MUSIC &&
            getOptions(line, index, TYPE.OPTION)?.map((opt) =>
              getOptionRule(baseRule, TYPE.OPTION, opt.name),
            )) ||
          null,
        passtopProductType:
          (line.product?.name === PROTECTION_SHAPE.PASSTOP_OS &&
            getOption(line, index, TYPE.PASSTOP_OS_PRODUCT_TYPE)?.label) ||
          null,
      };
    };

    const [OG, OD] = [getOreille(0), getOreille(1)];

    console.log({OG, OD});

    const files = quote.transfer.prints.map((print) => print.name);

    return {
      PATIENT: {
        nom: quote.patient?.name,
        age: quote.patient?.age,
        surdite:
          (quote.patient &&
            typeof quote.patient.deafness !== 'undefined' &&
            DEAFNESS_LABEL[quote.patient.deafness]) ||
          null,
        serial: quote.serialNumber,
        sexe: quote.patient?.gender && quote.patient?.gender.slice(0, 1),
      },
      OREILLE: OREILLEStr,
      OG,
      OD,
      files: files,
      originalFiles: files,
    } as Details;
  });
};

const getTempOrder = (quote: BaseQuoteData, user: User): TempOrder => {
  // assert(quote.transporter?.ID !== undefined);
  // assert(quote.shippingAddress !== undefined);

  let dateLivrSouh: string | number | undefined = quote.shippingDate;
  if (dateLivrSouh) {
    dateLivrSouh = new Date(dateLivrSouh).valueOf();
  } else {
    dateLivrSouh = new Date().valueOf();
  }

  // FIXME assert instead optional values instead of || ''
  return {
    /* eslint-disable @typescript-eslint/camelcase */
    clientID: quote.clientId,
    TRP: quote.transporter?.ID || '',
    dateLivrSouh,
    message: quote.comment || '',
    livr_id: quote.shippingAddress?.ID || '',
    livr_rs: quote.shippingAddress?.raisonSociale || '',
    livr_adr: quote.shippingAddress?.adresse || '',
    livr_cp: quote.shippingAddress?.codePostal || '',
    livr_ville: quote.shippingAddress?.ville || '',
    livr_pays: quote.shippingAddress?.isoCountry || 'FR',
    contact: '', // set-server-side (user.contact)
    email: '', // set server-side (user.email)
    details: getOrderDetails(quote),
    ATT_attente: !!quote.waiting,
    ATT_motif: quote.waiting,
    noBoite: quote.boxNumber,
    modeEmpreinte: quote.transfer.method + 1,
    numeroCommandeEmpreintesPrec: quote.transfer.alreadyTransferredOrderId,
    numAffaire: quote.clientOrderNumber,
    /* eslint-enable @typescript-eslint/camelcase */
  };
};

export const getOrder = (
  quote: BaseQuoteData,
  user: User,
  detailsIndex: number,
): Partial<Order> => {
  const {rs, adr, cp, ville} = user.adr_livr;

  return {
    /* eslint-disable @typescript-eslint/camelcase */
    // ID: '',
    id_client: {__KEY: user.ID, __STAMP: 1},
    // etat: 1,
    // etatNegoce: 0,
    dateSaisie: new Date().valueOf() / 1000,
    departSociete: new Date().valueOf() / 1000,
    numeroBon: '',
    // numeroAffaire: '',
    contact: user.contact,
    email: user.email,
    // profil: 2,
    // etatFabrication: 2,
    patient: quote.patient?.name,
    livr_ville: ville,
    livr_adresse: adr,
    livr_codePostal: cp,
    livr_raisonSociale: rs,
    oreille: getSelectedEars(quote.lines[detailsIndex].options.ears),
    details: getOrderDetails(quote)[detailsIndex],
    /* eslint-enable @typescript-eslint/camelcase */
  };
};

const preventUnload = (event: BeforeUnloadEvent): void => {
  // Cancel the event
  event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  event.returnValue = '';
};

export type FinalizeError = {error: 'other' | 'attachments'};
export type FinalizeResult = Order | FinalizeError;

export const isFinalizeError = (
  result: FinalizeResult,
): result is FinalizeError => !!(result as FinalizeError).error;

const TEMP_ID_CHARACTERS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const generateTempID = (length = 10): string => {
  let result = '';
  for (let index = 0; index < length; ++index) {
    result += TEMP_ID_CHARACTERS.charAt(
      Math.floor(Math.random() * TEMP_ID_CHARACTERS.length),
    );
  }
  return result;
};

export const finalize = async (
  session: WakandaService,
  quote: BaseQuoteData,
  lines: BaseQuoteLine[],
  user: User,
): Promise<FinalizeResult> => {
  try {
    if (REACT_APP_OFFLINE_MODE) {
      return {
        ID: '',
      } as Order;
    }

    window.addEventListener('beforeunload', preventUnload);

    const tempID = generateTempID();
    const {prints} = quote.transfer;
    if (prints.length) {
      const formData = new FormData();
      formData.append('TEMP_ID', tempID);
      prints.map(
        ({originFileObj: print}) => print && formData.append('prints[]', print),
      );

      let fileUploadRetry = 3;
      let fileUploadSuccess = false;
      do {
        --fileUploadRetry;
        try {
          const res = await (
            await fetch(
              `${process.env.REACT_APP_WAKANDA_HOST}/Commandes/UploadFiles`,
              {
                method: 'POST',
                credentials: 'include',
                body: formData,
              },
            )
          ).json();

          console.log('upload res', res);
          fileUploadSuccess = res?.success ? true : false;
          if (fileUploadSuccess) {
            break;
          }
        } catch (err) {
          console.error('upload error', err);
        }
      } while (fileUploadRetry > 0);

      if (!fileUploadSuccess) {
        return {error: 'attachments'};
      }
    }

    const commande = getTempOrder(quote, user);

    const order =
      (await session.fetch<Order>('Commandes', 'saveCdeEmbouts', {
        ID: quote.lines[0].id,
        ...commande,
        tempID,
        lignes: lines.map(
          (line) =>
            line.articles?.map(({ID, qty, totalHT}) => ({ID, qty, totalHT})) ||
            [],
        ),
      })) || undefined;

    if (!order || !order.ID) {
      return {error: 'other'};
    }

    return order;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return {error: 'other'};
  } finally {
    window.removeEventListener('beforeunload', preventUnload);
  }
};

export const getRule = (value: string): string => {
  return value
    .slice(1, -2)
    .split('_')
    .slice(2)
    .join('_');
};

export const getLabelFromRule = (
  value: string,
  rules: any,
  labels: any,
  includes = false,
): string => {
  const rule = getRule(value);

  const entry = (Object.entries(rules) as [string, string][]).find(
    ([_, entry]) =>
      entry === rule || entry === value || (includes && entry.includes(value)),
  );

  console.log('getLabelFromRule', {rules, rule, value, entry, includes});

  return entry ? labels[entry[0]] : value;
};

export const getStringEnumValues = <
  EnumKey extends string,
  EnumValue extends string
>(
  enumVariable: {[key in EnumKey]: EnumValue},
): EnumValue[] => Object.values(enumVariable) as EnumValue[];

export const getNumericEnumValues = <
  EnumKey extends string,
  EnumValue extends number
>(
  enumVariable: {[key in EnumKey]: EnumValue},
): EnumValue[] =>
  (Object.values(enumVariable) as EnumValue[]).filter(
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    (key: EnumValue) => typeof enumVariable[Number(key)] === 'string',
  );
